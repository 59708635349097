import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { useSocketIO } from 'react-use-websocket';

import { useAuth } from '../Auth';
import { API } from '../Config';

import { SocketContext } from './SocketContext';

import { getWsStatus } from '@/lib/utils';

export type SocketProviderProps = { children?: ReactNode };

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [isWsInit, setIsWsInit] = useState(false);
  const lastIdRef = useRef<string>();
  const {
    sendMessage: wsSendMessage,
    lastMessage: wsLastMessage,
    readyState: wsReadyState,
  } = useSocketIO(API.ws);

  const initWs = useCallback(
    (id: string) => {
      if (id !== lastIdRef.current) {
        wsSendMessage(`42["init",{"foreignId":"${id}"}]`);
        setIsWsInit(true);
        lastIdRef.current = id;
      }
    },
    [wsSendMessage],
  );

  const { brainSessionId } = useAuth();

  // Auto init
  useEffect(() => {
    if (wsReadyState === 1 && brainSessionId?.id) {
      initWs(brainSessionId.id);
    }
  }, [wsReadyState, brainSessionId, initWs]);

  const { wsStatus, wsComplete, wsError } = getWsStatus(wsLastMessage);

  return (
    <SocketContext.Provider
      value={{
        isWsInit,
        wsSendMessage,
        wsLastMessage,
        wsReadyState,
        initWs,
        wsStatus,
        wsComplete,
        wsError,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
