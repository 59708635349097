import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';

import useLocalStorage from '../../hooks/LocalStorage';
import { useAuth } from '../Auth';
import { DOMAIN } from '../Config';

const BrainSessionContext = createContext(undefined);

function BrainSessionProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [showChatContainer, setShowChatContainer] = useState(false);
  const [chatOptions, setChatOptions] = useState(null);
  const [isBobInit, setIsBobInit] = useState(false);
  const [creditScoreRating, setCreditScoreRating] = useState(0);
  const [closeModalUrl, setCloseModalUrl] = useLocalStorage(
    DOMAIN.closeModalUrl,
    null,
  );
  const { brainSessionId, userId } = useAuth();

  const saveCloseModalUrl = useCallback(
    (url) => {
      setCloseModalUrl(url);
    },
    [setCloseModalUrl],
  );

  // TODO: rename to `showChat`
  const toggleChat = useCallback((options) => {
    setChatOptions(options);
    setShowChatContainer(Boolean(options));
  }, []);

  const closeChatModal = () => {
    setShowChatContainer(false);
    setChatOptions(null);
  };

  useEffect(() => {
    setIsBobInit(true);
    const params = new URLSearchParams(window.location.search);
    const flowParam = params.get('flow');
    const stepParam = params.get('step');
    if (flowParam && stepParam) {
      toggleChat({ step: stepParam, goal: flowParam });
    }
    setLoading(false);
  }, [toggleChat]);

  const value = {
    isBobInit,
    brainSessionId,
    loading,
    chatOptions,
    showChatContainer,
    closeModalUrl,
    creditScoreRating,
    setCreditScoreRating,
    userId,
    toggleChat,
    closeChatModal,
    saveCloseModalUrl,
  };

  return (
    <BrainSessionContext.Provider value={value}>
      {children}
    </BrainSessionContext.Provider>
  );
}

BrainSessionProvider.propTypes = {
  children: PropTypes.node,
};

BrainSessionProvider.defaultProps = {
  children: null,
};

export { BrainSessionContext, BrainSessionProvider };
