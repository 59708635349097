import appStringsNo from '../assets/constants/appStrings-no';
import routesNo, { economyPages } from '../assets/constants/routes-no';

export const env = import.meta.env.VITE_STAGE || 'release';
const config = {
  release: {
    domain: 'https://scoopr.no',
    api: {
      bobUri: 'https://api.scoopr.no/brain/api/legion/',
      registerSource: 'https://api.scoopr.no/bi/api/registersource/',
      url: 'https://api.scoopr.no',
      ws: 'wss://wso.scoopr.no',
    },
  },
  dev: {
    domain: 'https://alpha.scoopr.no',
    api: {
      bobUri: 'https://api.alpha.scoopr.no/brain/api/legion/',
      registerSource: 'https://api.alpha.scoopr.no/bi/api/registersource/',
      url: 'https://api.alpha.scoopr.no',
      ws: 'wss://wso.alpha.scoopr.no',
    },
  },
  sandbox: {
    domain: 'https://sandbox.scoopr.no',
    api: {
      bobUri: 'https://api.sandbox.scoopr.no/brain/api/legion/',
      registerSource: 'https://api.sandbox.scoopr.no/bi/api/registersource/',
      url: 'https://api.sandbox.scoopr.no',
      ws: 'wss://wso.sandbox.scoopr.no',
    },
  },
};

export const API = {
  bobUri: config[env].api.bobUri,
  registerSource: config[env].api.registerSource,
  api: config[env].api.url,
  ws: config[env].api.ws,
  url: config[env].api.url,
  domain: config[env].domain,
};

export const LANG_CODE = {
  nb: 'nb-NO',
};

export const LANG = {
  appStrings: appStringsNo,
  routes: routesNo,
  economyRoutes: economyPages,
};

const GTM_DATA_STREAM = {
  release: '_ga_GH2XW8SZWJ',
  dev: '_ga_NLJYP1311M',
  sandbox: '_ga_K1EPT8NSG8',
};

export const GTM = {
  // id: env !== 'release' ? 'GTM-WN3TQZP' : 'GTM-WN3TQZP',
  id: env !== 'release' ? 'GTM-TW4S9CJ' : 'GTM-WN3TQZP',
  sessionIdCookie: GTM_DATA_STREAM[env],
};

export const HJ = {
  hjid: 1854012,
  hjsv: 6,
};

export const COOKIEBOOT = {
  cid: 'ad589a4e-20a9-413c-970b-6657d68559de',
};

export const DOMAIN = {
  registerSourceSession: 'SCOOPR_REGISTER_SOURCE_SESSION',
  closeModalUrl: 'SCOOPR_MODAL_URL',
  /** @deprecated to be removed */
  lang: 'SCOOPR_CURRENT_LANG',
  /** @deprecated to be removed */
  cookies: 'SCOOPR_COOKIES',
  myScooprUser: 'SCOOPR_USER_DATA',
};
