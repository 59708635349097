import { styled } from '@mui/material';
import { useNProgress } from '@tanem/react-nprogress';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useNavigation } from 'react-router-dom';

export type NProgressProps = {
  // Empty
};

export const NProgress: React.FC<NProgressProps> = () => {
  const navigation = useNavigation();
  const [isAnimating, setIsAnimating] = useState(false);
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  useEffect(() => {
    let timeout: number;

    if (navigation.state !== 'idle') {
      timeout = window.setTimeout(() => {
        setIsAnimating(true);
      }, 200);
    } else {
      setIsAnimating(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [navigation.state]);

  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
    </Container>
  );
};

const Container = styled('div', {
  shouldForwardProp: (p) =>
    !['animationDuration', 'isFinished'].includes(p as string),
})<{ animationDuration: number; isFinished: boolean }>(
  ({ animationDuration }) => ({
    pointerEvents: 'none',
    transition: `opacity ${animationDuration}ms linear`,

    variants: [
      {
        props: { isFinished: true },
        style: { opacity: 0 },
      },
    ],
  }),
);

const Bar = styled('div', {
  shouldForwardProp: (p) =>
    !['animationDuration', 'progress'].includes(p as string),
})<{
  animationDuration: number;
  progress: number;
}>(({ theme, progress, animationDuration }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: 4,
  zIndex: 999999999999,
  backgroundColor: theme.palette.primary.main,
  marginLeft: `${(progress - 1) * 100}%`,
  transition: `margin-left ${animationDuration}ms linear`,
}));
