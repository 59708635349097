import { matchPath } from 'react-router-dom';

import routes from '@/assets/constants/routes-no';
import type { CheckManyConsentsResponse, Me } from '@/lib/api/queries';

export type MyScooprRedirectArgs = {
  me: Me;
  consents: CheckManyConsentsResponse;
  currentPath: string;
};

export const getMyScooprRedirect = ({
  me,
  consents,
  currentPath,
}: MyScooprRedirectArgs): string | undefined => {
  const getRedirect = () => {
    if (!me.email) {
      return routes.MY_SCOOPR_EMAIL;
    } else if (!me.phone_number) {
      return routes.MY_SCOOPR_PHONE;
    } else if (!consents.all_check) {
      return routes.MY_SCOOPR_CONSENTS;
    }
  };

  const redirectUrl = getRedirect();

  if (redirectUrl && !matchPath(redirectUrl, currentPath)) {
    return redirectUrl;
  }
};
