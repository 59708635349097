import { useAuth } from '@/services/Auth';

export const SIZES = {
  topBar: {
    height: {
      small: 56,
      large: 80,
    },
  },
  navigationChange: 1270, // breakpoint
  layout: {
    width: {
      xs: 700,
      sm: 900,
      md: 1200,
      lg: 1400,
      xl: 1500,
    },
  },
};

export const LINKS = {
  LOG_IN: 'https://minside.scoopr.no/',
  FACEBOOK: 'https://www.facebook.com/Scoopr-103103178189677/',
  TIKTOK: 'https://www.tiktok.com/@scoopr.no',
  GJELDSREGISTERET: 'https://gjeldsregisteret.no',
  TRUST_PILOT: 'https://no.trustpilot.com/review/scoopr.no',
};

export const useMenuItems = (appStrings, routes) => {
  const { isAnonymous } = useAuth();

  return [
    {
      title: appStrings.MENU.PRODUCTS_REFINANCING,
      href: routes.PRODUCTS_REFINANCING,
    },
    {
      title: appStrings.MENU.MY_SCOOPR,
      href: isAnonymous ? routes.MY_SCOOPR_LOGIN : routes.MY_SCOOPR,
    },
    {
      title: appStrings.MENU.DEBT_REGISTER_MENU,
      href: routes.DEBT_REGISTER,
    },
    {
      title: 'Kampanjer',
      href: routes.COMPETITION_WINNERS,
    },
    {
      title: appStrings.MENU.CUSTOMER_SERVICE,
      href: routes.CUSTOMER_SERVICE,
    },
  ];
};
