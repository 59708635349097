import { createMutationFn } from './utils';

import type { RegisterSourceData } from '@/lib/analytics/registerSource';
import { apiClient } from '@/lib/api/apiClient';

export const applyConsents = createMutationFn({
  fn: (consents: string[]) =>
    apiClient.patch('bi/consent-clusters/apply_consents/', {
      json: {
        slugs: consents,
      },
    }),
  invalidates: [['consents']],
});

export const updateRegisterSource = createMutationFn({
  fn: async (data: RegisterSourceData) => {
    const { slug, ...custom_data } = data;
    return apiClient.post('bi/api/registersource/', {
      json: { slug, custom_data },
    });
  },
  invalidates: [],
});
