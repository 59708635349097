import { addHours, isBefore, previousSunday } from 'date-fns';

import { LANG } from './Config';

export const logoutRedirect = () => {
  if (window.location.pathname !== LANG.routes.LOGGED_OUT) {
    window.location.href = LANG.routes.LOGGED_OUT;
  }
};

export class NoDate extends Date {
  __type = 'NoDate' as const;
}

/**
 *
 * @param year
 * @param month Number between 1 and 12 unlike `new Date()` which uses 0-11 range
 * @param day
 * @param hours
 * @param minutes
 * @param seconds
 */
export const createDateInNorwegianTime = (
  year: number,
  month: number,
  day: number,
  hours: number,
  minutes = 0,
  seconds = 0,
): NoDate => {
  const p = (n: number) => n.toString().padStart(2, '0');

  // In both cases it's necessary to find last Sunda of given month.
  // It has to be done in local time because depending on the timezone
  // offset it may produce incorrect results when using UTC time.
  // Then day has to be passed to UTC date to enable timezone independent calculations.

  // Last sunday of March at 01:00 UTC (start of DST in EU)
  // well actually 02:00 because one hour magically disappears
  const dstStartLocal = previousSunday(new Date(`${year}-04-01T02:00:00`));
  const dstStart = new Date(`${year}-03-${dstStartLocal.getDate()}T02:00:00Z`);
  // Last sunday of October at 01:00 UTC (end of DST in EU)
  const dstEndLocal = previousSunday(new Date(`${year}-11-01T01:00:00`));
  const dstEnd = new Date(`${year}-10-${dstEndLocal.getDate()}T01:00:00Z`);

  // Date in UTC without any timezone correction
  const utcDate = new Date(
    `${year}-${p(month)}-${p(day)}T${p(hours)}:${p(minutes)}:${p(seconds)}Z`,
  );

  // Assume date is not in DST, check if it is before DST starts
  const utcDateNoDst = addHours(utcDate, -1);
  const isBeforeDst = isBefore(utcDateNoDst, dstStart);

  // Assume date is in DST, check if it is after DST ends
  const utcDateDst = addHours(utcDate, -2);
  const isAfterDst = !isBefore(utcDateDst, dstEnd);

  const isDst = !isBeforeDst && !isAfterDst;
  const date = isDst ? utcDateDst : utcDateNoDst;

  return new NoDate(date);
};
