import { API } from '../../Config';
import Http from '../Http';

function apiGetAccountMe() {
  return Http.get(`${API.url}/account/me/`);
}

function apiDeleteAccountDelete() {
  return Http.delete(`${API.url}/account/delete/`);
}

function apiGetAccountLogin() {
  return Http.get(`${API.url}/account/login/`);
}

function apiGetAccountLoginBankId() {
  return Http.get(`${API.url}/account/login/?authentication_provider=BANK_ID`);
}

function apiGetAccountLoginVipps() {
  return Http.get(`${API.url}/account/login/?authentication_provider=VIPPS`);
}

/**
 * @typedef {object} PatchAccountMeArgs
 * @property {string} [email]
 * @property {string} [phoneNumber]
 */

function apiPatchAccountMe(
  /** @type {PatchAccountMeArgs} */ { email, phoneNumber },
) {
  return Http.patch(`${API.url}/account/me/`, {
    email,
    phone_number: phoneNumber,
  });
}

function apiPostAccountLogout(access, refresh) {
  return Http.post(`${API.url}/account/logout/`, {
    access,
    refresh,
  });
}

function apiPostAuthToken(brainSessionId) {
  const data = {};
  if (brainSessionId) {
    data.brain_session_id = brainSessionId;
  }
  return Http.post(`${API.url}/authorization/session/token/`, data);
}

function apiPostAuthTokenRefresh(refresh) {
  return Http.post(`${API.url}/authorization/session/token/refresh/`, {
    refresh,
  });
}

function apiPostConsentClustersAllConsents(consents) {
  return Http.post(`${API.url}/bi/consent-clusters/all_consents_check/`, {
    slugs: [...consents],
  });
}

/** @deprecated use functions from `methods.ts` or `queries.ts` */
export const AuthHttp = {
  get: {
    accountMe: apiGetAccountMe,
    accountLogin: apiGetAccountLogin,
    accountLoginBankId: apiGetAccountLoginBankId,
    accountLoginVipps: apiGetAccountLoginVipps,
  },
  post: {
    accountLogout: apiPostAccountLogout,
    authToken: apiPostAuthToken,
    authTokenRefresh: apiPostAuthTokenRefresh,
    consentsCheckAll: apiPostConsentClustersAllConsents,
  },
  patch: {
    accountMe: apiPatchAccountMe,
  },
  delete: {
    accountDelete: apiDeleteAccountDelete,
  },
};
