import { CssBaseline } from '@mui/material';
import { LazyMotion } from 'framer-motion';
import {
  ScrollRestoration,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';

import routes from './assets/constants/routes-no';
import { december2023Routes } from './competitions/2023/december/pages/routes';
import { april2024Routes } from './competitions/2024/april/pages/routes';
import { february2024Routes } from './competitions/2024/february/pages/routes';
import { january2024Routes } from './competitions/2024/january/pages/routes';
import { march2024Routes } from './competitions/2024/march/pages/routes';
import { may2024Routes } from './competitions/2024/may/pages/routes';
import { summerExtra2024Routes } from './competitions/2024/summer-extra/pages/routes';
import { summer2024Routes } from './competitions/2024/summer/pages/routes';
import { Analytics } from './components/Analytics';
import Layout from './components/Layout';
import { NProgress } from './components/NProgress';
import { authInitQuery, competitionWinnersQuery } from './lib/api/queries';
import { simpleRace, smartLazy } from './lib/router-utils';
import LegacyRoutes from './pages/LegacyRoutes';
import { myScooprRoutes } from './pages/MyScoopr/routes';
import { queryClient } from './queryClient';
import { AuthProvider } from './services/Auth';
import { BrainSessionProvider } from './services/BrainSession/BrainSession';
import { SocketProvider } from './services/socket/SocketProvider';

const loadFeatures = () =>
  import('./framer-features.ts').then((res) => res.default);

// Can be moved to its own file in the future
// eslint-disable-next-line react-refresh/only-export-components
const Root = () => (
  <AuthProvider>
    <BrainSessionProvider>
      <SocketProvider>
        <NProgress />
        <CssBaseline />
        <Analytics />
        <ScrollRestoration />
        <LazyMotion features={loadFeatures} strict>
          <Layout />
        </LazyMotion>
      </SocketProvider>
    </BrainSessionProvider>
  </AuthProvider>
);

// eslint-disable-next-line react-refresh/only-export-components
const ForwardError = () => {
  // Pass errors to sentry's error boundary higher up in the tree
  throw useRouteError();
};

export const router = createBrowserRouter(
  [
    {
      path: '/',
      Component: Root,
      // Ignore errors in production build (hopefully we'll get proper error screen in the future)
      ErrorBoundary: import.meta.env.PROD ? ForwardError : undefined,
      HydrateFallback: () => (
        <div className="full-page-loader">
          <img width="200" src="/logo.png" alt="Scoopr logo" />
        </div>
      ),
      loader: async () => {
        queryClient.prefetchQuery(authInitQuery());
        return null;
      },
      children: [
        { path: '*', Component: LegacyRoutes },
        { path: '/', lazy: () => smartLazy(import('./pages/Home')) },
        {
          path: routes.PRODUCTS_REFINANCING,
          lazy: () => smartLazy(import('./pages/Refinancing')),
        },
        {
          path: routes.PRODUCTS_REFINANCING_CHAT,
          lazy: () => smartLazy(import('./pages/Refinancing')),
        },
        {
          path: routes.ABANDONED_SESSIONS_CHAT,
          lazy: () => smartLazy(import('./pages/Refinancing')),
        },
        {
          path: routes.DEBT_REGISTER,
          lazy: () => smartLazy(import('./pages/DebtRegister')),
        },
        {
          path: routes.CUSTOMER_SERVICE,
          lazy: () => smartLazy(import('./pages/CustomerService')),
        },
        {
          path: routes.COMPETITION_WINNERS,
          loader: () =>
            simpleRace([queryClient.prefetchQuery(competitionWinnersQuery())]),
          lazy: () => smartLazy(import('./pages/CompetitionWinners')),
        },
        ...myScooprRoutes,
        ...december2023Routes,
        ...january2024Routes,
        ...february2024Routes,
        ...march2024Routes,
        ...april2024Routes,
        ...may2024Routes,
        ...summer2024Routes,
        ...summerExtra2024Routes,
      ],
    },
  ],
  {
    future: {
      v7_partialHydration: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
    },
  },
);
