import Cookies from 'js-cookie';

import { APP_VERSION, COMMIT_ID } from '../version';

import { DOMAIN } from '@/services/Config';

export type RegisterSourceData = {
  params: Record<string, string>;
  slug: string;
  http_referrer: string;
  ga_cookie: string;
  user_agent: string;
  fbp: string;
  fbc: string;
  gcl_au: string;
  gcl_aw: string;
  gcl_dc: string;
  url_path: string;
  ttclid: string;
  _ttp: string;
  snapchat_uuid_c1: string;
  page_url: string;
  app_version: string;
};

const getSourceName = (): string => {
  const params = new URLSearchParams(window.location.search);

  return (
    params.get('sourceid') ||
    params.get('utm_source') ||
    document.referrer.match(/([^?]+)(\?.*)?/)?.[1] ||
    ''
  );
};

const getFreshData = (): Partial<RegisterSourceData> => ({
  params: Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  ),
  slug: getSourceName(),
  http_referrer: document.referrer,
  ga_cookie: Cookies.get('_ga'),
  user_agent: window.navigator.userAgent,
  fbp: Cookies.get('_fbp'),
  fbc: Cookies.get('_fbc'),
  gcl_au: Cookies.get('_gcl_au'),
  gcl_aw: Cookies.get('_gcl_aw'),
  gcl_dc: Cookies.get('_gcl_dc'),
  url_path: window.location.pathname,
  ttclid: Cookies.get('ttclid'),
  _ttp: Cookies.get('_ttp'),
  snapchat_uuid_c1: Cookies.get('uuid_c1'),
  page_url: window.location.href,
});

const getSavedData = (): Partial<RegisterSourceData> => {
  try {
    const json = sessionStorage.getItem(DOMAIN.registerSourceSession);
    if (json) {
      return JSON.parse(json) || {};
    }
  } catch {}

  return {};
};

/**
 * Gets register source data from both fresh and saved sources.
 * Automatically refreshes saved data.
 */
export const getRegisterSourceData = (): RegisterSourceData => {
  const fresh = getFreshData();
  const saved = getSavedData();

  const get = <T extends keyof RegisterSourceData>(key: T) =>
    fresh[key] || saved[key] || '';

  const data = {
    params: { ...saved.params, ...fresh.params },
    slug: get('slug') || 'direct',
    http_referrer: get('http_referrer'),
    ga_cookie: get('ga_cookie'),
    user_agent: get('user_agent'),
    fbp: get('fbp'),
    fbc: get('fbc'),
    gcl_au: get('gcl_au'),
    gcl_aw: get('gcl_aw'),
    gcl_dc: get('gcl_dc'),
    url_path: get('url_path'),
    ttclid: get('ttclid'),
    _ttp: get('_ttp'),
    snapchat_uuid_c1: get('snapchat_uuid_c1'),
    page_url: get('page_url'),
    app_version: `${APP_VERSION} (${COMMIT_ID})`,
  };

  // Update saved data
  saveRegisterSourceData(data);

  return data;
};

const saveRegisterSourceData = (data: RegisterSourceData) =>
  sessionStorage.setItem(DOMAIN.registerSourceSession, JSON.stringify(data));
