import type { SxProps } from '@mui/material';
import type { LegacyRef, MutableRefObject, RefCallback } from 'react';
import * as v from 'valibot';

export const getWsStatus = (msg: Record<string, any> | null | undefined) => {
  const wsStatus = msg?.payload?.status;
  const wsComplete = wsStatus === 'COMPLETE';
  const wsError = ['DATA_FETCHING_ERROR', 'PARSING_ERROR', 'ERROR'].includes(
    wsStatus,
  );
  return { wsStatus, wsComplete, wsError };
};

export type PromiseHash = Record<string, Promise<unknown> | undefined>;

export type AwaitedPromiseHash<Hash extends PromiseHash> = {
  [Key in keyof Hash]: Awaited<Hash[Key]>;
};

/**
 * Get a hash of promises and await them all.
 * Then return the same hash with the resolved values.
 * @example
 * export async function loader({ request }: LoaderFunctionArgs) {
 *   return json(
 *     promiseHash({
 *       user: getUser(request),
 *       posts: getPosts(request),
 *     })
 *   );
 * }
 * @example
 * export async function loader({ request }: LoaderFunctionArgs) {
 *   return json(
 *     promiseHash({
 *       user: getUser(request),
 *       posts: promiseHash({
 *         list: getPosts(request),
 *         comments: promiseHash({
 *           list: getComments(request),
 *           likes: getLikes(request),
 *         }),
 *       }),
 *     })
 *   );
 * }
 */
// Code borrowed from `remix-utils` package
export const promiseHash = async <Hash extends PromiseHash>(
  hash: Hash,
): Promise<AwaitedPromiseHash<Hash>> =>
  Object.fromEntries(
    await Promise.all(
      Object.entries(hash).map(async ([key, promise]) => [key, await promise]),
    ),
  );

export const contextsFromSchemaError = (error: v.ValiError<any>) => {
  const issues = v.flatten(error.issues);
  const contexts: Record<string, any> = {};

  if (issues.root) {
    contexts['Schema Errors (root)'] = issues.root;
  }

  if (issues.nested) {
    contexts['Schema Errors (nested)'] = issues.nested;
  }

  if (issues.other) {
    contexts['Schema Errors (other)'] = issues.other;
  }

  return contexts;
};

export const mergeRefs =
  <T = any>(
    ...refs: Array<MutableRefObject<T> | LegacyRef<T>>
  ): RefCallback<T> =>
  (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };

/**
 * Returns given amount of minutes as milliseconds
 */
export const minutes = (n: number) => n * 60 * 1000;

export const mergeSx = (...styles: (SxProps<any> | undefined)[]): SxProps =>
  styles.filter(Boolean).flatMap((s) => (Array.isArray(s) ? s : [s]));
