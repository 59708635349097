import { ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { PNavLink } from '@/ui/PLink';

const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  navItemText: {
    '& > span': {
      fontSize: '1.125rem',
      fontWeight: typography.fontWeightSemiBold,
    },
  },
  menuItem: {
    display: 'flex',
    color: palette.text.default,
    justifyContent: 'flex-start',
    padding: spacing(1.5, 8),
    width: '100%',
  },
  menuItemActive: {
    color: palette.text.primary,
  },
}));

const NavItem = ({ menuItem, onClick, ...rest }) => {
  const { classes } = useStyles();
  const { title, href } = menuItem;

  return (
    <ListItemLink
      to={href}
      {...rest}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ListItemText className={classes.navItemText}>{title}</ListItemText>
    </ListItemLink>
  );
};

NavItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  onClick: PropTypes.any,
};
NavItem.defaultProps = {
  onClick: undefined,
};

export default NavItem;

const ListItemLink = ({ ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <ListItem
      button
      disableRipple
      disableGutters
      className={classes.menuItem}
      component={React.forwardRef(({ className, ...linkProps }, ref) => (
        <PNavLink
          ref={ref}
          className={({ isActive }) =>
            cx(className, isActive && classes.menuItemActive)
          }
          {...linkProps}
        />
      ))}
      {...props}
    />
  );
};
