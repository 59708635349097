import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import type { EVENTS } from './legacyEvents';

import { GTM } from '@/services/Config';

const envs = {
  dev: {
    auth: 'Qt1PyqNroNqhIHL5HuzlxQ',
    preview: 'env-7',
  },
  prod: {
    auth: 'xD4PUOPlW6TgqTuA3fxYOg',
    preview: 'env-2',
  },
  alpha: {
    auth: 'SmCkqVYrYDiyX39pSOE11g',
    preview: 'env-33',
  },
  poc: {},
};

const envVariable: Record<string, any> = {
  localhost: { ...envs.dev },
  'hudya.no': { ...envs.prod },
  'scoopr.no': { ...envs.prod },
  'dev.scoopr.no': { ...envs.dev },
  'alpha.scoopr.no': { ...envs.poc },
  'sandbox.scoopr.no': { ...envs.poc },
};

export const initializeGTM = () => {
  TagManager.initialize({
    gtmId: GTM.id,
    ...envVariable[window.location.hostname],
  });
};

const getOptimizeCookie = () => {
  const optimizeCookie = Cookies.get('SCOOPR_OPTIMIZE_EXP');
  if (!optimizeCookie) {
    return {};
  }

  const relevantPart = optimizeCookie.substring(2);
  const keyValuePairs = relevantPart.split(',');

  const result = Object.fromEntries(
    keyValuePairs.map((pair) => pair.split('.')),
  );

  return {
    experiments_data: JSON.stringify(result),
  };
};

const addValue = (value: object, parameters?: object) => {
  const experimentsData = getOptimizeCookie();

  const dataLayerObj = {
    ...value,
    ...experimentsData,
    ...parameters,
  };

  TagManager.dataLayer({
    dataLayer: dataLayerObj,
  });
};

export const addGTMValue = (value: object) => addValue(value);

export type GTMEvent = {
  action: string;
  category?: string;
  event?: string;
};

export const addGTMEvent = (event: GTMEvent, parameters?: object) =>
  addValue({ category: 'tracking', event: event.action, ...event }, parameters);

export const getGaClientId = () => {
  const ga = (window as any).ga;

  if (!ga) {
    return '';
  }

  let clientId = '';
  ga((tracker: any) => {
    clientId = tracker.get('clientId');
  });
  return clientId;
};

const getLegacyEventConfig = async (action: keyof typeof EVENTS) => {
  const { EVENTS } = await import('./legacyEvents');

  const config = EVENTS[action];

  if (config) {
    return config;
  } else {
    const fallback = {
      action: action,
      category: 'tracking',
      event: action,
    };
    Sentry.captureMessage(`Invalid analytics event ${action}`, {
      extra: { action, fallback },
    });
    console.warn(
      `WARNING: Event '${action}' is not a valid event! It needs to be properly configured.`,
      'Sending fallback configuration instead:',
      fallback,
    );
    return fallback;
  }
};

export const addLegacyGTMEvent = async (
  action: keyof typeof EVENTS,
  parameters?: object,
) => {
  const experimentsData = getOptimizeCookie();
  const config = await getLegacyEventConfig(action);

  const dataLayerObj = {
    ...config,
    ...experimentsData,
    ...parameters,
  };

  TagManager.dataLayer({
    dataLayer: dataLayerObj,
  });
};
