import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { logoutRedirect } from '../new-utils';
import {
  getCurrentTokensOperation,
  getTokens,
  obtainNewTokens,
  refreshTokens,
  removeTokens,
  saveTokens,
  type Tokens,
} from '../tokens';

import { canObtainNewTokens, canRefreshTokens } from '@/lib/api/apiClient';

const baseURL = '';

export const Http = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

Http.interceptors.request.use(
  async (config) => {
    config.withCredentials = true;
    await getCurrentTokensOperation();
    const token = getTokens();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as AxiosRequestConfig;

    if (error?.response?.status === 401) {
      const tokens = getTokens();
      let newTokens: Tokens | undefined;

      if (canRefreshTokens(tokens)) {
        try {
          newTokens = await refreshTokens();
        } catch {}
      }

      if (
        !newTokens &&
        canObtainNewTokens(originalRequest.url, safeParse(originalRequest.data))
      ) {
        try {
          newTokens = await obtainNewTokens();
        } catch {}
      }

      if (newTokens) {
        saveTokens(newTokens);
        return Http(originalRequest);
      } else {
        removeTokens();
        logoutRedirect();
        console.error('Could not refresh or obtain new token');
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  },
);

const safeParse = (data: string) => {
  try {
    return JSON.parse(data);
  } catch {}
  return undefined;
};

export default Http;
